<template>
  <div class="container" id="container">
    <div class="top">四川民族网络博览数据展示平台</div>
    <div class="box">
      <div class="left_box">
        <div class="fbox card_box">
          <div class="title">促成量排行榜</div>
          <div class="card_content">
            <div
              class="rank"
              v-show="turnoverRank && turnoverRank.length > 0"
              v-for="(item, index) of turnoverRank"
              :key="index"
            >
              <span class="index"
                >{{ index + 1 }}
                <img
                  style="
                    width: 1rem;
                    height: 0.5rem;
                    position: absolute;
                    top: 0.1rem;
                  "
                  v-if="index === 0"
                  src="@/assets/img/tag.png"
                />
              </span>
              <span class="content">{{ item.title }}</span>
              <div
                class="sector"
                v-if="index === 0"
                style="position: relative; margin-right: 0; visibility: hidden"
              >
                <img
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    right: '0',
                    bottom: '-0.3rem'
                  }"
                  src="../../assets/img/red_circle.png"
                />
                <div
                  style="
                    position: absolute;
                    top: 0.15rem;
                    right: 0.36rem;
                    font-size: 0.24rem;
                    color: #fff;
                  "
                >
                  {{ item.facilitateNumber }}
                </div>
              </div>
              <div
                class="sector"
                v-else-if="index === 1 || index === 2"
                style="position: relative; margin-right: 0; visibility: hidden"
              >
                <img
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    right: '0',
                    bottom: '-0.3rem'
                  }"
                  src="../../assets/img/white_circle.png"
                />
                <div
                  style="
                    position: absolute;
                    top: 0.15rem;
                    right: 0.36rem;
                    font-size: 0.24rem;
                    color: #fff;
                  "
                >
                  {{ item.facilitateNumber }}
                </div>
              </div>
              <div v-else class="num" style="visibility: hidden">
                {{ item.facilitateNumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="sbox card_box">
          <div class="title">小程序热门文章</div>
          <div class="card_content">
            <div
              class="rank"
              v-for="(item, index) of wxaHot"
              v-show="wxaHot && wxaHot.length > 0"
              :key="index"
              style="height: 0.8rem"
            >
              <!-- <div class="radar"></div> -->
              <div class="sector" style="position: relative">
                <img
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '-0.32rem'
                  }"
                  src="../../assets/img/radar.png"
                />
              </div>
              <!-- <span class="index">{{ item.id }}</span> -->
              <span class="content">{{ item.title }}</span>
              <div class="num" style="visibility: hidden">
                {{ item.viewsNumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="tbox card_box">
          <div class="title">官网热门文章</div>
          <div class="card_content">
            <div
              class="rank"
              v-for="(item, index) of officeRank"
              :key="index"
              style="height: 0.8rem"
            >
              <div class="sector" style="position: relative">
                <img
                  v-show="index === 0"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '-0.32rem'
                  }"
                  src="../../assets/img/sector_blue.png"
                />
                <img
                  v-show="index === 1"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '-0.32rem'
                  }"
                  src="../../assets/img/sector_green.png"
                />
                <img
                  v-show="index === 2"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '-0.32rem'
                  }"
                  src="../../assets/img/sector_orange.png"
                />
              </div>
              <!-- <span class="index">{{ item.id }}</span> -->
              <span class="content">{{ item.title }}</span>
              <div class="num" style="visibility: hidden">
                {{ item.viewsNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mid_box">
        <div class="total_num">
          <div class="date_picker">
            <div class="label">当前选择日期：</div>
            <div class="picker">
              <el-date-picker
                style="font-size: 0.36rem"
                size="small"
                v-model="time"
                type="month"
                @change="changeDate"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="total_list">
            <div class="list" :style="listHeight">
              <div class="name">{{ dealData.name }}</div>
              <!-- <div class="num">{{dealData.num}}</div> -->
              <countTo
                class="num"
                :startVal="0"
                :separator="separator"
                :autoplay="autoplay"
                :endVal="dealData.num"
                :duration="3000"
              ></countTo>
            </div>
            <div class="list" :style="listHeight">
              <div class="name">{{ askData.name }}</div>
              <!-- <div class="num">{{askData.num}}</div> -->
              <countTo
                class="num"
                :startVal="2"
                :separator="separator"
                :autoplay="autoplay"
                :endVal="askData.num"
                :duration="3000"
              ></countTo>
            </div>
            <div class="list" :style="listHeight">
              <div class="name">{{ scanData.name }}</div>
              <!-- <div class="num">{{scanData.num}}</div> -->
              <countTo
                class="num"
                :startVal="0"
                :separator="separator"
                :autoplay="autoplay"
                :endVal="scanData.num"
                :duration="3000"
              ></countTo>
            </div>
          </div>
        </div>
        <div class="banner">
          <el-carousel indicator-position="none" height="47vh">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img class="b_img" :src="item.cover" alt="" />
              <div class="b_title">{{ item.title }}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          v-if="isEchart"
          class="charts_container"
          id="charts"
          ref="charts"
        ></div>
      </div>
      <div class="right_box">
        <div class="top_box card_box">
          <div class="title">小程序浏览详情</div>
          <div class="card_content wechat">
            <div
              class="rank"
              v-for="(item, index) of scanList"
              :key="index"
              style="padding-left: 0.6rem; padding-right: 0.6rem"
            >
              <div class="sector">
                <img
                  v-show="index === 0"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '30%'
                  }"
                  src="../../assets/img/double_circle_blue.png"
                />
                <img
                  v-show="index === 1"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '30%'
                  }"
                  src="../../assets/img/double_circle_green.png"
                />
                <img
                  v-show="index === 2"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '30%'
                  }"
                  src="../../assets/img/double_circle_orange.png"
                />
                <img
                  v-show="index === 3"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '30%'
                  }"
                  src="../../assets/img/double_circle_red.png"
                />
              </div>
              <!-- <span class="index">{{ item.id }}</span> -->
              <span class="content" style="padding-left: 8%">{{
                item.data
              }}</span>
              <div class="num">{{ item.total }}</div>
            </div>
          </div>
        </div>
        <div class="bottom_box card_box">
          <div class="title">官网浏览详情</div>
          <div class="card_content">
            <div
              class="rank"
              v-for="(item, index) of oscanList"
              :key="index"
              style="padding-left: 0.6rem; padding-right: 0.6rem"
            >
              <div class="sector">
                <img
                  v-show="index === 0"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '26%'
                  }"
                  src="../../assets/img/circle_purple.png"
                />
                <img
                  v-show="index === 1"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '26%'
                  }"
                  src="../../assets/img/circle_blue.png"
                />
                <img
                  v-show="index === 2"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '26%'
                  }"
                  src="../../assets/img/circle_green.png"
                />
                <img
                  v-show="index === 3"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '26%'
                  }"
                  src="../../assets/img/circle_orange.png"
                />
                <img
                  v-show="index === 4"
                  class="circle-dec"
                  :style="{
                    width: '1rem',
                    height: '1rem',
                    position: 'absolute',
                    top: '26%'
                  }"
                  src="../../assets/img/circle_red.png"
                />
              </div>
              <!-- <span class="index">{{ item.id }}</span> -->
              <span class="content" style="padding-left: 8%">{{
                item.data
              }}</span>
              <div class="num">{{ item.total }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <div>
        <img src="../../assets/img/white_circle.png" />
        <div>正在加载数据，请稍候...</div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  name: 'data_visible',
  components: { countTo },
  data() {
    return {
      time: '',
      separator: ',', // 统计数字分隔符
      autoplay: true, // 统计数字变更时自动刷新
      windowWidth: document.getElementById('app').clientWidth,
      turnoverRank: [], // 促成量排行榜
      wxaHot: [], // 小程序热门排行榜
      officeRank: [], // 官网热门文章
      scanList: [], // 小程序浏览详情
      oscanList: [], // 官网浏览详情
      dealData: { name: '促成量', num: 0 },
      askData: { name: '访问量', num: 0 },
      scanData: { name: '浏览量', num: 0 },
      bannerList: [],
      timer: null,
      newTimer: null,
      isEchart: false,
      xData: [],
      yData: [],
      alpha: 30,
      speed: 0,
      loading: false,
      cache: {}
    }
  },
  computed: {
    listHeight() {
      if (this.windowWidth < 1600) {
        return 'height: 2rem'
      } else {
        return 'height: 3rem'
      }
    }
  },
  methods: {
    changeDate(val) {
      const date = new Date(val)
      const year = date.getFullYear()
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      this.time = year + '-' + month
      this.getDetail()
    },
    startMove(opTarget) {
      const that = this
      clearInterval(that.timer)
      const opDiv = document.getElementById('container')
      opDiv.style.backgroundColor = 'black'
      that.timer = setInterval(function () {
        if (that.alpha < opTarget) {
          that.speed = 10
        } else if (that.alpha > opTarget) {
          that.speed = -10
        }
        if (that.alpha === opTarget) {
          opDiv.style.backgroundColor = '#00031b'
          clearInterval(that.timer)
        } else {
          that.alpha += that.speed
          // console.log('alpha--->', that.alpha)
          opDiv.style.opacity = that.alpha / 100
          opDiv.style.filter = 'alpha(opacity=' + that.alpha + ')'
        }
      }, 1000)
    },
    mycharts() {
      const myChart = this.$echarts.init(document.getElementById('charts'))
      const option = {
        title: {
          text: '热门村寨浏览量',
          textStyle: {
            color: '#fff',
            fontSize: 22
          }
        },
        tooltip: {},
        legend: {},
        xAxis: {
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 14
            }
          },
          data: this.xData
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.15)'
            }
          }
        },
        series: [
          {
            type: 'bar',
            data: this.yData,
            barWidth: 50,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#351fff' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#206fff' }
              ]),
              border: '1px solid #fff'
            }
          }
        ],
        animationDuration: 3000,
        animationEasing: 'cubicInOut'
      }
      myChart.setOption(option)
    },
    setDatas(res) {
      if (this.time === '2021-11') {
        res.data.allFacilitateCount = 73015
        res.data.visits = 83561
      }
      if (this.time === '2021-12') {
        res.data.allFacilitateCount = 74475
        res.data.visits = 85233
      }
      const a = res.data
      this.dealData.num = a.wxaFacilitateCount
      this.askData.num = a.visits
      this.scanData.num = a.allFacilitateCount
      this.turnoverRank = a.facilitate
      this.wxaHot = a.wxaHot
      this.officeRank = a.webHot
      this.scanList = a.wxaDataItemVos
      this.oscanList = a.webDataItemVos
      this.bannerList = a.images
      const arr = a.dataItemVos.slice(0, 5)
      arr.forEach((ele) => {
        this.xData.push(ele.data)
        this.yData.push(ele.total)
      })
      this.isEchart = false
      this.loading = false
      this.$nextTick(() => {
        this.mycharts()
      })
    },
    // 获取统计页码数据
    getDetail() {
      this.isEchart = false
      this.xData = []
      this.yData = []
      const url = '/api/documents/getStatistic?time=' + this.time
      if (this.cache[this.time] != null) {
        this.setDatas(this.cache[this.time])
        return
      }
      this.loading = true
      this.$axios
        .get(url)
        .then((res) => {
          this.cache[this.time] = res
          this.setDatas(this.cache[this.time])
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    const year = new Date().getFullYear()
    const month =
      new Date().getMonth() + 1 < 10
        ? '0' + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    this.time = year + '-' + month
    this.getDetail()
    this.startMove(100)
    // this.mycharts()
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 1600px) {
  .total_list .list {
    height: 2rem;
  }
}
.picker {
  .el-input__prefix {
    display: none;
  }
  .el-input__inner {
    border: 1px solid #ffffff;
    border-radius: 13px;
    background: transparent;
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.container {
  font-size: 0.36rem;
  .el-scrollbar__wrap {
    // height: auto;
  }
  .el-input--mini .el-input__inner {
    width: 2.5rem;
    background-color: transparent;
    border-radius: 0.5rem;
    border: 0.01rem solid #fff;
    color: #fff;
  }
  .el-input__icon.el-icon-date {
    color: #fff;
  }
  .el-scrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #00031b;
  //  background: red;
  filter: alpha(opacity=30);
  opacity: 0;
}
.loading {
  background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: white;
    > img {
      height: 50px;
      width: 50px;
      animation: loading 1s infinite linear;
      transform-origin: center center;
    }
    > div {
      color: white;
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.top {
  height: 2rem;
  width: 100%;
  opacity: 1;
  font-size: 1rem;
  font-family: "PingFangSC", "PingFangSC-Medium";
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 2rem;
  background: url("../../assets/img/top.png") center center no-repeat;
  background-size: 100% 100%;
}
.wechat .rank:nth-child(1) {
  margin-bottom: 10%;
}
.wechat .rank:nth-child(2) {
  margin-bottom: 10%;
}
.box {
  //   position: fixed;
  background: #00031b;
  width: 100%;
  display: flex;
  .card_box {
    width: 100%;
    background: url("~@/assets/img/img_frame_bg.png") no-repeat center center /
        100% 100%,
      url("~@/assets/img/card_box_left_top.png") left top no-repeat,
      url("~@/assets/img/card_box_right_bottom.png") right bottom no-repeat;
    background-color: #040e36;
    border: 1px solid;
    padding: 0.2rem;
    .card_content {
      height: calc(100% - 0.5rem);
    }
  }
  .left_box {
    // width: 8.5rem;
    width: 23%;
    position: fixed;
    top: 0.8rem;
    left: 0.5rem;
    bottom: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .fbox {
      .rank {
        height: 20%;
      }
      height: calc(40% - 0.4rem);
      /**水波图start */

      @keyframes toRotate {
        50% {
          transform: translate(-50%, -70%) rotate(180deg);
        }
        100% {
          transform: translate(-50%, -70%) rotate(360deg);
        }
      }
      /**水波图end */
    }
    .sbox {
      height: 30%;
      /**雷达扫描图start */
      .rank {
        height: 33.33% !important;
      }

      @keyframes radar-beam {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes blips {
        14% {
          background: radial-gradient(
            2vmin circle at 75% 70%,
            #ffffff 10%,
            #20ff4d 30%,
            rgba(255, 255, 255, 0) 100%
          );
        }
        14.0002% {
          background: radial-gradient(
              2vmin circle at 75% 70%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 63% 72%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            );
        }
        25% {
          background: radial-gradient(
              2vmin circle at 75% 70%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 63% 72%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 56% 86%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            );
        }
        26% {
          background: radial-gradient(
              2vmin circle at 75% 70%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 63% 72%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 56% 86%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            );
          opacity: 1;
        }
        100% {
          background: radial-gradient(
              2vmin circle at 75% 70%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 63% 72%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              2vmin circle at 56% 86%,
              #ffffff 10%,
              #20ff4d 30%,
              rgba(255, 255, 255, 0) 100%
            );
          opacity: 0;
        }
      }
      /**雷达扫描图end */
    }
    .tbox {
      height: 30%;
      .rank {
        height: 33.33% !important;
      }
      /**扇形雷达start */
      @keyframes change {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(90deg);
        }

        50% {
          transform: rotate(180deg);
        }

        75% {
          transform: rotate(270deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
      /**扇形雷达end */
    }
  }
  .title {
    background: url("~@/assets/img/img_frame_bg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 0.48rem;
    font-family: "PingFangSC", "PingFangSC-Medium";
    font-weight: 500;
    color: #ffffff;
    line-height: 0.84rem;
    height: 0.84rem;
    padding-left: 0.2rem;
  }
  /**水波图start */
  .wave_container {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    .show_num {
      position: absolute;
      top: 0.15rem;
      right: 0.16rem;
    }
  }
  .wave {
    position: relative;
    width: 0.6rem;
    height: 0.6rem;
    text-align: center;
    line-height: 0.6rem;
    color: #fff;
    background-image: linear-gradient(-180deg, #3b7bdb 13%, #4d6fdf 91%);
    border-radius: 50%;
  }
  .wave-mask {
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    top: 0;
    left: 50%;
    border-radius: 40%;
    background-color: rgba(212, 24, 24, 0.9);
    transform: translate(-50%, -70%) rotate(0);
    animation: toRotate 10s linear -5s infinite;
    z-index: 20;
    background-color: rgba(255, 255, 255, 0.9);
  }
  /**水波图 end */
  /**雷达扫描图start */
  .radar {
    background: -webkit-radial-gradient(
        center,
        rgba(32, 255, 77, 0.3) 0%,
        rgba(32, 255, 77, 0) 75%
      ),
      -webkit-repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(
              32,
              255,
              77,
              0
            )
            18%, #20ff4d 18.6%, rgba(32, 255, 77, 0) 18.9%),
      -webkit-linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d
            50%, rgba(32, 255, 77, 0) 50.2%),
      -webkit-linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d
            50%, rgba(32, 255, 77, 0) 50.2%);
    background: radial-gradient(
        center,
        rgba(32, 255, 77, 0.3) 0%,
        rgba(32, 255, 77, 0) 75%
      ),
      //   repeating-radial-gradient(
      //     rgba(32, 255, 77, 0) 5.8%,
      //     rgba(32, 255, 77, 0) 18%,
      //     #20ff4d 18.6%,
      //     rgba(32, 255, 77, 0) 18.9%
      //   ),
      linear-gradient(
          90deg,
          rgba(32, 255, 77, 0) 49.5%,
          #20ff4d 50%,
          #20ff4d 50%,
          rgba(32, 255, 77, 0) 50.2%
        ),
      linear-gradient(
        0deg,
        rgba(32, 255, 77, 0) 49.5%,
        #20ff4d 50%,
        #20ff4d 50%,
        rgba(32, 255, 77, 0) 50.2%
      );
    width: 0.5rem;
    height: 0.5rem;
    position: relative;
    left: 0.3rem;
    top: 0.2rem;
    margin-right: 0.15rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    // border: 0.2rem solid #20ff4d;
    overflow: hidden;
  }
  .radar:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: blips 5s infinite;
    animation-timing-function: linear;
    animation-delay: 1.4s;
  }
  .radar:after {
    content: " ";
    display: block;
    background-image: linear-gradient(
      44deg,
      rgba(0, 255, 51, 0) 50%,
      #00ff33 100%
    );
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  /**雷达扫描图end */
  /**扇形雷达start */
  .sector {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    // margin-right: .15rem;
    // border-radius: 0.5rem 0 0 0;
    // border: #fff;
    // background-color: red;
    // text-align: center;
    // line-height: 0.5rem;
    // float: left;
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    .circle-dec {
      animation: spin 5s linear 1s infinite reverse;
    }
  }
  /**扇形雷达end */
  .rank {
    height: 1rem;
    // margin-top: 0.3rem;
    font-size: 18px;
    font-family: "PingFangSC", "PingFangSC-Regular";
    font-weight: 400;
    color: #ffffff;
    display: flex;
    position: relative;
    align-items: center;
    .index {
      display: inline-block;
      background: linear-gradient(299deg, #001aa1 13%, #07a9ff 91%);
      border-radius: 50%;
      height: 0.7rem;
      width: 0.7rem;
      line-height: 0.7rem;
      margin-left: 10px;
      color: #fff;
      text-align: center;
      font-size: 0.36rem;
    }
    .content {
      font-size: 0.36rem !important;
      flex: 1;
      padding: 0 0.1rem 0 0.5rem;
      color: #fff;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap;
    }
    .num {
      background: linear-gradient(90deg, #5cf3ff, #0069ee 98%);
      color: #fff;
      font-size: 0.24rem;
      padding: 0.06rem 0.3rem;
      border-radius: 0.4rem;
      text-align: center;
    }
  }
  .mid_box {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: fixed;
    top: 1rem;
    // right: 9rem;
    // left: 9rem;
    right: 26%;
    left: 26%;
    bottom: 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    padding: 0.5rem 1rem;
    .total_num {
      height: 40%;
      font-size: 0.36rem;
      .date_picker {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.36rem;
        margin-top: 1.5rem;
        .label {
          font-size: 0.36rem;
          color: #fff;
        }
        .picker {
          .el-input__inner {
            border: 1px solid #ffffff;
            border-radius: 13px;
          }
        }
      }
      .total_list {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        .list {
          width: 30%;
          height: 3rem;
          background: url("~@/assets/img/img_data.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          .name {
            height: 45%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            color: #fff;
            font-size: 0.36rem;
          }
          .num {
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }
    }
    .banner {
      height: 60%;
      padding: 30px 0;
      .b_img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
      }
      .b_title {
        color: #fff;
        font-size: 18px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        z-index: 999999999999;
        background: #000;
        opacity: 0.5;
        margin-top: -55px;
      }
    }
    .charts_container {
      height: 32vh;
      font-size: 0.36rem;
      // border: .01rem solid #fff;
      margin-top: 1.3rem;
      color: #ffffff;
    }
  }
  .right_box {
    // width: 8.5rem;
    width: 23%;
    position: fixed;
    top: 0.8rem;
    right: 0.5rem;
    bottom: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top_box {
      height: calc(50% - 0.4rem);
      .rank {
        height: 25% !important;
      }
    }
    .bottom_box {
      height: 50%;
      .rank {
        height: 20% !important;
      }
    }
  }
}
</style>
